<template>
    <div
        class="map-container trans"
        @mouseenter="blockScroll = false"
        @mouseleave="blockScroll = true"
    >
        <GmapMap
            :center="center"
            :zoom="mapZoom"
            ref="mapRef"
            :options="mapStyle"
            class="map"
        >
            <DirectionsRenderer
                travelMode="DRIVING"
                :origin="origin"
                :map="map"
                :destination="destination"
            />
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                :icon="{ url: require('../../assets/images/marker.png') }"
                @click="markerClick(m)"
            />
        </GmapMap>
        <div
            :style="{ height: navHeight + 'px' }"
            @mouseleave="mouseLeave"
            @mouseup="mouseLeave"
            @mouseover="mouseOver"
            @touchend="mouseLeave"
            @touchcancel="mouseLeave"
            @touchstart="mouseOver"
            class="markers-container"
        >
            <transition-group name="marker" mode="out-in" tag="div">
                <div key="start" class="first-container" v-if="!activeId">
                    <div
                        :class="{ active: showMarkers }"
                        class="choose-location"
                    >
                        <div class="content-container" @click="toggleMarkers">
                            <span class="title">{{
                                _tr("Choose Location")
                            }}</span>

                            <input
                                ref="searchInput"
                                class="where-to-find-input"
                                type="text"
                                autofocus
                                v-model="search"
                                :placeholder="_tr('Choose Location')"
                            />
                            <svg
                                class="icon"
                                width="14.738"
                                height="15.99"
                                viewBox="0 0 14.738 15.99"
                            >
                                <g
                                    id="Group_411"
                                    data-name="Group 411"
                                    transform="translate(13.324 14.576) rotate(180)"
                                >
                                    <line
                                        id="Line_3"
                                        data-name="Line 3"
                                        x2="5.968"
                                        y2="6.243"
                                        transform="translate(5.943 0)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_4"
                                        data-name="Line 4"
                                        x1="5.943"
                                        y2="6.243"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_5"
                                        data-name="Line 5"
                                        y2="12.704"
                                        transform="translate(5.955 0.872)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div
                        :style="{ height: markersSelectHeight + 'px' }"
                        class="marker-list"
                    >
                        <div class="marker-list-content">
                            <div
                                v-for="(item, index) in markers"
                                :key="index"
                                @click="markerSelect(item)"
                                class="marker-item"
                            >
                                <div class="content">
                                    <span class="text"
                                        >{{ item.City }},
                                        {{ item.Country }}</span
                                    >
                                    <span class="location">{{
                                        item.Address
                                    }}</span>
                                </div>
                                <svg
                                    class="icon"
                                    width="14.738"
                                    height="15.99"
                                    viewBox="0 0 14.738 15.99"
                                >
                                    <g
                                        id="Group_411"
                                        data-name="Group 411"
                                        transform="translate(13.324 14.576) rotate(180)"
                                    >
                                        <line
                                            id="Line_3"
                                            data-name="Line 3"
                                            x2="5.968"
                                            y2="6.243"
                                            transform="translate(5.943 0)"
                                            fill="none"
                                            stroke="#0a141c"
                                            stroke-linecap="round"
                                            stroke-width="2"
                                        />
                                        <line
                                            id="Line_4"
                                            data-name="Line 4"
                                            x1="5.943"
                                            y2="6.243"
                                            transform="translate(0 0)"
                                            fill="none"
                                            stroke="#0a141c"
                                            stroke-linecap="round"
                                            stroke-width="2"
                                        />
                                        <line
                                            id="Line_5"
                                            data-name="Line 5"
                                            y2="12.704"
                                            transform="translate(5.955 0.872)"
                                            fill="none"
                                            stroke="#0a141c"
                                            stroke-linecap="round"
                                            stroke-width="2"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div key="inside" v-else class="inside-component">
                    <div @click="goBack" class="header">
                        <div class="header-contain">
                            <svg
                                class="icon"
                                width="14.738"
                                height="15.99"
                                viewBox="0 0 14.738 15.99"
                            >
                                <g
                                    id="Group_411"
                                    data-name="Group 411"
                                    transform="translate(13.324 14.576) rotate(180)"
                                >
                                    <line
                                        id="Line_3"
                                        data-name="Line 3"
                                        x2="5.968"
                                        y2="6.243"
                                        transform="translate(5.943 0)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_4"
                                        data-name="Line 4"
                                        x1="5.943"
                                        y2="6.243"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_5"
                                        data-name="Line 5"
                                        y2="12.704"
                                        transform="translate(5.955 0.872)"
                                        fill="none"
                                        stroke="#0a141c"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                            <span class="title">{{ _tr("Back") }}</span>
                        </div>
                    </div>
                    <div class="inside-content">
                        <div class="nav">
                            <span class="text"
                                >{{ activeMarker.City }}
                                {{ activeMarker.Country }}</span
                            >
                            <span class="location">{{
                                activeMarker.Address
                            }}</span>
                        </div>
                        <div class="nav">
                            <span class="text">{{ activeMarker.Phone }}</span>
                            <span class="text">{{ activeMarker.Email }}</span>
                        </div>
                        <img :src="activeMarker.path" class="image" alt="" />
                        <a
                            :href="activeMarker.Link"
                            target="_blank"
                            class="button"
                        >
                            <div class="content">
                                <span class="title">{{
                                    _tr("Get Directions")
                                }}</span>
                                <div class="icon-container">
                                    <svg
                                        class="icon"
                                        width="12.373"
                                        height="17.322"
                                        viewBox="0 0 12.373 17.322"
                                    >
                                        <path
                                            id="Icon_ionic-md-pin"
                                            data-name="Icon ionic-md-pin"
                                            d="M12.937,2.25A6.143,6.143,0,0,0,6.75,8.313c0,4.547,6.187,11.259,6.187,
                  11.259s6.187-6.712,6.187-11.259A6.143,6.143,0,0,0,12.937,2.25Zm0,
                  8.228a2.166,2.166,0,1,1,2.21-2.165A2.167,2.167,0,0,1,12.937,10.478Z"
                                            transform="translate(-6.75 -2.25)"
                                            fill="#E5B676"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </transition-group>
            {{ navHeight }}
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DirectionsRenderer from "@/components/custom/DirectionsRenderer";
import axios from "axios";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        name: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            locationData: null,
            defaultPos: null,
            center: { lat: 10, lng: 10 },
            zoom: null,
            mapStyle: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUI: false,
                styles: [
                    {
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#f5f5f5"
                            }
                        ]
                    },
                    {
                        elementType: "labels.icon",
                        stylers: [
                            {
                                visibility: "off"
                            }
                        ]
                    },
                    {
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#616161"
                            }
                        ]
                    },
                    {
                        elementType: "labels.text.stroke",
                        stylers: [
                            {
                                color: "#f5f5f5"
                            }
                        ]
                    },
                    {
                        featureType: "administrative.land_parcel",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#bdbdbd"
                            }
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#eeeeee"
                            }
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#757575"
                            }
                        ]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#e5e5e5"
                            }
                        ]
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e"
                            }
                        ]
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#ffffff"
                            }
                        ]
                    },
                    {
                        featureType: "road.arterial",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#757575"
                            }
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#dadada"
                            }
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#616161"
                            }
                        ]
                    },
                    {
                        featureType: "road.local",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e"
                            }
                        ]
                    },
                    {
                        featureType: "transit.line",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#e5e5e5"
                            }
                        ]
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#eeeeee"
                            }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                            {
                                color: "#c9c9c9"
                            }
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [
                            {
                                color: "#9e9e9e"
                            }
                        ]
                    }
                ]
            },
            showMarkers: false,
            activeId: null,
            init: false,
            origin: null,
            destination: null,
            map: null,
            scrollTimeout: null,
            search: ""
        };
    },
    components: {
        DirectionsRenderer
    },
    watch: {
        showMarkers(val) {
            val
                ? setTimeout(() => {
                      this.$refs.searchInput?.focus();
                      // this.$refs.searchInput.click();
                  }, 450)
                : "";
        }
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice",
            scroll: "scroll/getScroll",
            menu: "menu/getIndexData"
        }),
        markers() {
            if (this.search.length > 1) {
                let searchVal = this.search.toUpperCase();
                const filteredData = this.data?.list?.filter(
                    item =>
                        item.City.toUpperCase().includes(searchVal) ||
                        item.Country.toUpperCase().includes(searchVal) ||
                        item.Address.toUpperCase().includes(searchVal)
                );
                return filteredData?.map(item => {
                    return {
                        ...item,
                        position: {
                            lat: Number(item?.Latitute),
                            lng: Number(item?.Longitute)
                        },
                        path: item?.image?.[0]?.devices[this.device?.type]
                    };
                });
            }
            return this.data?.list?.map(item => {
                return {
                    ...item,
                    position: {
                        lat: Number(item?.Latitute),
                        lng: Number(item?.Longitute)
                    },
                    path: item?.image?.[0]?.devices[this.device?.type]
                };
            });
        },
        activeMarker() {
            return this.markers?.find(item => item.id === this.activeId) || {};
        },
        markersSelectHeight() {
            let maxHeight = 777;
            let height = document
                .querySelector(".marker-list-content")
                ?.getBoundingClientRect()?.height;

            if (this.device?.size < 1650) {
                maxHeight = 590;
            }
            if (this.device?.size < 1440) {
                maxHeight = 457;
            }
            if (this.device?.size < 1366) {
                maxHeight = 590;
            }
            if (this.device?.size < 1024) {
                maxHeight = 415;
            }
            if (this.device?.size < 768) {
                maxHeight = 350;
            }

            return this.showMarkers
                ? height > maxHeight
                    ? maxHeight
                    : height
                : 0;
        },
        navHeight() {
            if (!this.init) return undefined;
            let HeaderHeight = document
                .querySelector(".choose-location")
                ?.getBoundingClientRect()?.height;

            let markersHeight = this.showMarkers
                ? HeaderHeight + this.markersSelectHeight
                : HeaderHeight;
            let insideHeight = document
                .querySelector(".inside-component")
                ?.getBoundingClientRect()?.height;
            return this.device?.size < 769
                ? this.activeId
                    ? insideHeight
                    : markersHeight
                : undefined;
        },
        mapZoom() {
            if (this.zoom === null) {
                return Number(this.menu?.siteSettings?.zoom);
            } else {
                return this.zoom;
            }
        }
    },
    methods: {
        async getDefCountry() {
            var BRK = true;
            let lat = [];
            let lng = [];
            const response = await axios
                .get("https://ipinfo.io/212.72.155.254?token=5b9e6dff60aeb4")
                .then(response => {
                    return response;
                })
                .catch(err => {
                    return err;
                });
            if (response.status === 200) {
                this.locationData = response.data;
                for (var i = 0; i < this.locationData?.loc.length; i++) {
                    if (this.locationData?.loc[i] === ",") {
                        BRK = false;
                    }
                    if (BRK) {
                        lat.push(this.locationData?.loc[i]);
                    }
                    if (!BRK) {
                        lng.push(this.locationData?.loc[i]);
                    }
                }
                lng = lng.slice(1);
                lat = lat.join("");
                lng = lng.join("");

                this.center = {
                    lat: Number(lat),
                    lng: Number(lng)
                };
            }
            return true;
        },
        mouseEnterFun() {
            this.scroll.stop();
        },
        markerSelect(val) {
            this.activeId = val.id;
            this.center = val.position;
        },
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.origin = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
            });
        },
        setCenter() {
            let item = this.data?.list[0];

            this.center = {
                lat: Number(item?.Latitute),
                lng: Number(item?.Longitute)
            };
        },
        getDirection() {
            this.destination = this.activeMarker.position;
        },
        markerClick(m) {
            this.toggleMarkers();
            this.center = m.position;
            this.activeId = m.id;
            this.zoom = 3;
        },
        goBack() {
            this.activeId = null;
            this.destination = null;
            window.dispatchEvent(new Event("delete_routes"));
        },
        mouseOver() {
            this.scroll?.stop();
        },
        mouseLeave() {
            this.scroll?.start();
        },
        toggleMarkers() {
            if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
            this.showMarkers = !this.showMarkers;
            this.scrollTimeout = setTimeout(() => {
                this.scroll?.update();
            }, 600);
        },
        gestureend() {
            window.addEventListener("touchstart", e => {
                if (e.touches.length === 2) {
                    this.scroll?.stop();
                }
            });
            window.addEventListener("touchend", () => {
                this.scroll?.start();
            });
            window.onwheel = () => {
                if (this.blockScroll) {
                    this.scroll?.start();
                } else {
                    this.scroll?.stop();
                }
                // if (e.ctrlKey) {
                //     this.scroll?.stop();
                // } else {
                //     this.scroll?.start();
                // }
            };
        }
    },
    mounted() {
        this.getDefCountry();
    },
    created() {
        this.setCenter();
        this.$nextTick(() => {
            this.init = true;
            this.geolocate();
            this.$refs.mapRef.$mapPromise.then(map => {
                this.map = map;
                // window.google.maps.event.addListener(map, 'zoom_changed', () => {
                //     console.log('zooommm')
                //     if (this.scrollTimeout) clearTimeout(this.scrollTimeout)
                //     this.scroll?.stop()
                //
                //     this.scrollTimeout = setTimeout(() => {
                //         this.scroll?.start()
                //     }, 50000)
                // });
            });
            this.gestureend();
        });
    },
    beforeDestroy() {
        if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
        this.scroll?.start();
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.23, 0.29, 0.33, 1);
$ease-in-out: cubic-bezier(0.28, 0.01, 0.29, 1);
.map-container {
    position: relative;
    @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }

    .map {
        width: 100%;
        height: 987px;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            height: 800px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            height: 625px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            height: 800px;
        }

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            height: 600px;
        }

        @media only screen and (max-width: 767px) {
            height: 300px;
        }
    }

    .markers-container {
        position: absolute;
        left: 75px;
        top: 55px;
        width: 420px;

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            left: 165px;
            top: 55px;
            width: 350px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            left: 128px;
            top: 34px;
            width: 350px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            left: 47px;
            top: 55px;
            width: 370px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            left: 47px;
            top: 55px;
            width: 345px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            position: relative;
            left: unset;
            top: unset;
        }

        .first-container {
            position: absolute;
            top: 0;
            width: 100%;
        }

        .choose-location {
            background: #e5b676;
            padding: 20px;
            cursor: pointer;

            .where-to-find-input {
                position: absolute;
                width: calc(100% - 80px);
                height: 35px;
                border: unset;
                border-bottom: 1px solid #e5b676;
                padding: 10px 5px;
                opacity: 0;
                visibility: hidden;
                transition: 0.4s $ease-in-out;
                @media (max-width: 1023px) {
                    visibility: visible;
                }

                &:focus {
                    outline: none;
                }

                &::placeholder {
                    color: #0a141c;
                    font-size: 21px;
                    opacity: 1;
                }
            }

            &.active {
                .title {
                    opacity: 0;
                    transition: 0.4s $ease-in-out !important;
                }

                .where-to-find-input {
                    opacity: 1;
                    visibility: visible;
                    transition: 0.4s 0.35s $ease-in-out;
                }

                .icon {
                    transform: rotate(180deg);
                }
            }

            .content-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 20px;
                background: white;

                .title {
                    color: #0a141c;
                    font-size: 21px;
                    transition: 0.4s 0.35s $ease-in-out;
                }
            }

            .icon {
                transition: transform 0.4s $ease-out;
            }
        }

        .text {
            color: #0a141c;
            margin-bottom: 5px;
            transition: all 0.4s $ease-out;
            display: block;
            text-align: left;
        }

        .location {
            color: #e5b676;
            display: block;
            text-align: left;
        }

        .marker-list {
            max-height: 777px;
            overflow-y: auto;
            transition: all 0.6s $ease-out;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                background: #cccccc;
            }

            &::-webkit-scrollbar-thumb {
                background: #740b43;
                width: 6px;
                border-radius: 30px;
            }

            .marker-list-content {
                overflow: auto;
            }

            .marker-item {
                @include space-between;
                padding: 20px;
                background: white;
                transition: all 0.4s $ease-out;
                cursor: pointer;
                border-bottom: 1px solid rgba(154, 154, 154, 0.2);

                &:hover {
                    background: #740b43;

                    .text {
                        color: white;
                    }

                    .icon {
                        line {
                            stroke: white;
                        }
                    }
                }

                .content {
                    font-size: 16px;
                    text-align: left;
                }

                .icon {
                    transform: rotate(-90deg);

                    line {
                        stroke: #740b43;
                        transition: all 0.4s $ease-out;
                    }
                }
            }
        }

        .inside-component {
            position: absolute;
            top: 0;
            width: 100%;
            //
            //@media only screen and (max-width: 768px) {
            //    width: 100%;
            //}

            .header {
                @include center-children;
                background: #740b43;
                padding: 36px 0;
                @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                    padding: 25px 0;
                }

                @media only screen and (max-width: 1023px) and (min-width: 768px) {
                    padding: 20px 0;
                }

                @media only screen and (max-width: 767px) {
                    padding: 15px 0;
                }

                .header-contain {
                    cursor: pointer;
                }

                .title {
                    color: white;
                    font-size: 21px;
                }

                .icon {
                    transform: rotate(90deg);
                    margin-right: 10px;

                    line {
                        stroke: white;
                    }
                }
            }

            .inside-content {
                background: white;
                padding: 20px;

                .nav {
                    margin-top: 20px;
                    @media only screen and (max-width: 1440px) {
                        margin-top: 15px;
                    }

                    &:first-child {
                        margin-top: unset;
                        padding-bottom: 20px;
                        border-bottom: 1px solid rgba(229, 182, 118, 0.2);
                        @media only screen and (max-width: 1440px) {
                            padding-bottom: 15px;
                        }
                    }
                }

                .image {
                    width: 100%;
                    margin: 20px 0 27px;
                    object-fit: cover;
                    height: 200px;

                    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                        margin: 15px 0 20px;
                        max-height: 200px;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        max-height: 180px;
                    }

                    @media only screen and (max-width: 1024px) {
                        margin: 15px 0;
                    }
                    @media only screen and (max-width: 767px) {
                        height: 150px;
                    }
                }

                .button {
                    @include center-children;
                    position: relative;
                    background: #e5b676;
                    cursor: pointer;
                    height: 60px;
                    text-decoration: unset;

                    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                        height: 50px;
                    }
                    @media only screen and (max-width: 1023px) {
                        height: 55px;
                    }

                    .title {
                        color: #740b43;
                        font-weight: bold;
                        font-size: 21px;
                        text-decoration: unset;
                        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                            font-size: 18px;
                        }
                        @media only screen and (max-width: 1023px) {
                            font-size: 16px;
                        }
                    }

                    .icon-container {
                        @include circle(20);
                        @include center-children;
                        background: #740b43;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 20px;

                        .icon {
                            width: 9px;
                        }
                    }
                }
            }
        }
    }
}

.marker-enter-active,
.marker-leave-active {
    transition: all 0.6s $ease-out;
}

.marker-enter,
.marker-leave-to {
    opacity: 0;
}
</style>
