import {MapElementFactory} from "vue2-google-maps";

export default MapElementFactory({
    name: "directionsRenderer",

    ctr() {
        return window.google.maps.DirectionsRenderer;
    },

    events: [],

    mappedProps: {},

    props: {
        origin: {type: Object},
        destination: {type: Object},
        travelMode: {type: String},
        map: {type: Object}
    },
    methods: {},
    afterCreate(directionsRenderer) {
        let directionsService = new window.google.maps.DirectionsService();
        var renderObjects = [];
        var markers = [];

        function makeMarker(position, icon, title, map) {
            let marker = new window.google.maps.Marker({
                position: position,
                map: map,
                icon: icon,
                title: title
            });
            markers.push(marker)
        }

        function clearRenderObjects() {
            for (let i in renderObjects) {
                renderObjects[i].setMap(null);
            }
            for (let i in markers) {
                markers[i].setMap(null);
            }
        }

        window.addEventListener('delete_routes', () => {
            clearRenderObjects()
        })
        this.$watch(
            () => [this.origin, this.destination, this.travelMode],
            () => {
                let {origin, destination, travelMode} = this;
                if (!origin || !destination || !travelMode) return;

                let trOr = new window.google.maps.LatLng(this.origin.lat, this.origin.lng)
                let trDs = new window.google.maps.LatLng(this.destination.lat, this.destination.lng)

                var icons = {
                    start: new window.google.maps.MarkerImage(
                        // URL
                        'http://maps.google.com/mapfiles/ms/micons/blue.png',
                        // (width,height)
                        new window.google.maps.Size(44, 32),
                        // The origin point (x,y)
                        new window.google.maps.Point(0, 0),
                        // The anchor point (x,y)
                        new window.google.maps.Point(22, 32)),
                    end: new window.google.maps.MarkerImage(
                        // URL
                        '',
                        // (width,height)
                        new window.google.maps.Size(44, 32),
                        // The origin point (x,y)
                        new window.google.maps.Point(0, 0),
                        // The anchor point (x,y)
                        new window.google.maps.Point(22, 32)),
                };

                directionsService.route(
                    {
                        origin: trOr,
                        destination: trDs,
                        travelMode
                    },
                    (response, status) => {
                        if (status !== "OK") return;
                        var leg = response.routes[0].legs[0];

                        makeMarker(leg.start_location, icons.start, "title", this.map);
                        makeMarker(leg.end_location, icons.end, 'title', this.map);
                        directionsRenderer.setOptions({
                            suppressMarkers: true,
                        })
                        directionsRenderer.setDirections(response);
                        renderObjects.push(directionsRenderer);
                    }
                );
            }
        );
    }
});
